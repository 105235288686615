<template>
  <div class="login page" v-if="show">
    <img class="logo" src="/logo.png" alt="" />
    <div class="tips">请使用微信授权登录</div>
    <button type="button" @click="login">微信授权登录</button>
  </div>
</template>

<style lang='scss' scord>
</style>

<script>
// import Cookie from "js-cookie";
import urlencode from "urlencode";
export default {
  data() {
    return {
      show: false
    };
  },

  async mounted () {
    const shop_id = this.$route.params.shop_id;
    const code = this.$route.query.code;
    console.log("------------------ code ------------------", code);

    if(!code) return this.show = true;
    
    const token = await this.$axios.post("/oauth/getWechatUserInfo", {
      code: code,
    });
    console.log("------------------ token ------------------", token);

    if (token) {
      this.show = false;
      this.$store.commit("saveToken", token.token);
      localStorage.setItem('token', token.token)
      // Cookie.set("token", token.token, { expires: token.expires });
      this.$router.push(localStorage.getItem('backTo') || '/'+shop_id);
    }
  },

  methods: {
    async login() {
      const redirectUrl = urlencode(this.$config.domain + this.$route.fullPath);

      const state = Date.now();
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.$config.AppID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;

      window.location.href = url;
    },
  },
};
</script>

